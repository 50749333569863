import React, { useState } from 'react';

function YoutubeVideoDownloader() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDownload = () => {
    const proxyUrl = 'http://localhost:2000/proxy';
    const videoUrl = `https://www.youtube.com/watch?v=${inputValue}`;

    fetch(proxyUrl, {
      method: 'POST',
      body: JSON.stringify({ url: videoUrl }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        const file = new Blob([buffer], { type: 'video/mp4' });
        const url = URL.createObjectURL(file);
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.download = 'video.mp4';
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(url);
        aTag.remove();
      })
      .catch(() => {
        alert('Failed to download file!');
      });
  };

  return (
    <>
      <div className="h-screen w-full bg-slate-100 pt-10">
        <div className="bg-white w-[50%] p-5 rounded-md m-auto">
          <h2 className="text-3xl font-semibold mb-7 text-center">Youtube Video Downloader</h2>
          <div className="flex items-center">
            <div className="w-[100%]">
              <input
                className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                onChange={handleInputChange}
                value={inputValue}
                placeholder="Paste video ID here"
                type="text"
                id="formFile"
              />
            </div>
            <div>
              <button
                className="bg-slate-600 hover:bg-slate-700 active:bg-slate-900 text-white py-[6px] px-4 ml-3 rounded-lg"
                onClick={handleDownload}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YoutubeVideoDownloader;
