import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import FakeNameGenerator from './components/FakeNameGenerator';
import ImageToTextGenerator from './components/ImageToTextGenerator';
import Imagetobase64Convertor from './components/Imagetobase64Convertor';
import ImageGenerator from './components/ImageGenerator';
import YoutubeThumbnailDownloader from './components/YoutubeThumbnailDownloader';
import YoutubeVideoDownloader from './components/youtubeVideoDownloader/YoutubeVideoDownlaoder';
import Home from './components/Home';
import Navbar from './components/Navbar';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/namegenerator" element={<FakeNameGenerator />} />
          <Route path="/youtubethumbnaildownloader" element={<YoutubeThumbnailDownloader />} />
          <Route path="/imagegenerator" element={<ImageGenerator />} />
          <Route path="/imagetobase64" element={<Imagetobase64Convertor />} />
          <Route path="/imagetotext" element={<ImageToTextGenerator />} />
          <Route path="/youtubevideodownload" element={<YoutubeVideoDownloader />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
