import React, { useState } from 'react';
import axios from 'axios';

function ImageToTextGenerator() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [extractedText, setExtractedText] = useState('');
    const [loading, setLoading] = useState('hidden');
    const [textResponse, setTextResponse] = useState('hidden');
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    const handleUpload = () => {
      if (!selectedFile) {
        alert('Please select a file.');
        return;
      }
  
      const formData = new FormData();
      formData.append('image', selectedFile);
  
      setLoading('block');
      setTextResponse('hidden');
      axios
        .post('http://localhost:2000/imagetobase64', formData)
        .then((response) => {
          setExtractedText(response.data.text);
          setLoading('hidden');
          setTextResponse('block');
        })
        .catch((error) => {
          console.error(error);
        });
    };

  const handleCopyText = () => {
    navigator.clipboard.writeText(extractedText);
    alert('Text copied to clipboard!');
  };

  const handleDownloadText = () => {
    const blob = new Blob([extractedText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Text.txt');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="h-screen w-full bg-slate-100 pt-10">
      <div className='lg:w-[50%] w-[90%] bg-white m-auto rounded-md md:p-10 p-5'>
        <h2 className='sm:text-3xl text-2xl font-semibold mb-7 text-center'>Image to Base64 Generator</h2>
          <div className='sm:flex items-center'>
            <div className='w-[100%]'>
              <input onChange={handleFileChange} className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary" type="file" id="formFile" />
            </div>
            <div>
              <button onClick={handleUpload} className='bg-slate-600 w-full sm:mt-0 mt-5 hover:bg-slate-700 active:bg-slate-900 text-white py-[6px] px-4 sm:ml-3 ml-0 rounded-lg'>Upload</button>
            </div>
          </div>
          <div>
            <img src="./loading.gif" className={`w-[200px] m-auto mt-8 ${loading} `} alt="" />
          </div>
          <div className={`border border-gray-600 mt-5 p-4 pt-2 rounded-lg ${textResponse} `}>
            <div className='float-right mb-3'>
              <button onClick={handleDownloadText} className='bg-slate-600 py-[4px] mr-3 px-3 text-[10px] text-white rounded-md'>Download</button>
              <button onClick={handleCopyText} className='bg-slate-600 py-[4px] px-3 text-[10px] text-white rounded-md'>Copy</button>
            </div>
            <p className='mt-7 w-full break-all max-h-[350px] overflow-auto'>
                data:image/jpeg;base64,{extractedText}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageToTextGenerator;
