import React from 'react'
import {Link} from "react-router-dom";

function Home() {
    return (
        <>
            <div className='w-full md:h-screen h-[100%] pt-20 bg-slate-100 px-10'>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10'>
                    <Link to="namegenerator">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Fake Name geenrator</h2>
                        <p>Generate realistic fake names for various purposes, with options for different genders and nationalities.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                    <Link to="imagetotext">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Image-to-Text Converter</h2>
                        <p>An innovative tool that converts images into text, facilitating easy understanding and accessibility.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                    <Link to="imagetobase64">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Image-to-Base64 Converter</h2>
                        <p>A vibrant sunset over a serene beach, painting the sky with a warm palette of colors.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                    <Link to="imagegenerator">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Image Generator</h2>
                        <p>A vibrant sunset over a serene beach, painting the sky with a warm palette of colors.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                    <Link to="youtubethumbnaildownloader">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Youtube Thumbnail Downloader</h2>
                        <p>A vibrant sunset over a serene beach, painting the sky with a warm palette of colors.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                    <Link to="youtubevideodownload">
                    <div className='bg-white px-7 py-12 rounded-md '>
                        <h2 className='font-bold text-xl py-3'>Youtube Video Downloader</h2>
                        <p>A vibrant sunset over a serene beach, painting the sky with a warm palette of colors.</p>
                        <div className="flex mt-4">
                            <button className="bg-[#2490EF] text-white rounded-md w-20 h-7 font-inter text-[13px] font-semibold">Try it</button>
                            <button className="w-24 h-8 font-SegoeUI text-sm font-normal flex items-center ml-4"> Learn more</button>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Home