import React, { useState } from "react";
import "../index.css";

function FakeNameGenerator() {
  const [gender, setGender] = useState('Random');
  const changeGender = (event) => {
    event.preventDefault();
    const innerValue = event.target.textContent;
    setGender(innerValue);
    setIsOpen(false)
  }

  const [name, setName] = useState('Random');
  const changeName = (event) => {
    event.preventDefault();
    const innerValue = event.target.textContent;
    setName(innerValue);
    setIsOpen2(false)
  }

  const [language, setLanguage] = useState('English');
  const changeLanguage = (event) => {
    event.preventDefault();
    const innerValue = event.target.textContent;
    setLanguage(innerValue);
    setIsOpen3(false)
  }

  const [inputNumber, setInputNumber] = useState('5');
  const handleInputNumber = (event) => {
    setInputNumber(event.target.value)
  }

  const [isOpen, setIsOpen] = useState(false);
  const [responseHide, setResponseHide] = useState('hidden');
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen(false);
    setIsOpen3(false);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen2(false);
    setIsOpen(false);
  };

  const submitData = () => {
    const data = {
      gender: gender.replace(/\s/g, '').toLowerCase(),
      name: name.replace(/\s/g, '').toLowerCase(),
      language: language.replace(/\s/g, '').toLowerCase(),
      quantity: inputNumber.replace(/\s/g, '').toLowerCase(),
    };

    fetch('http://localhost:2000/generateName', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setFetchedData(data)
        setResponseHide('block')
      })
      .catch(error => {
        console.error(error);
      });
  };


  return (
    <>
      <div className="h-screen w-full bg-slate-100 pt-10">
        <div className="sm:w-[80%] w-[90%] bg-white m-auto rounded-md">
        <h2 className='text-3xl font-semibold py-7 text-center'>Fake Name Generator</h2>
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 md:gap-0 gap-5 justify-items-center items-end md:px-5 px-0 py-5">
            <div className="relative inline-block w-28">
              <label className="bg-white">Gender</label>
              <button
                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={toggleDropdown}
              >
                {gender}
              </button>
              {isOpen && (
                <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      onClick={changeGender}
                    >
                      Random
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      value="male"
                      onClick={changeGender}
                    >
                      Male
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      value="female"
                      onClick={changeGender}
                    >
                      Female
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div className="relative inline-block w-28">
              <label className="bg-white">Name</label>
              <button
                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={toggleDropdown2}
              >
                {name}
              </button>
              {isOpen2 && (
                <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      onClick={changeName}
                    >
                      Random
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      value="firstname"
                      onClick={changeName}
                    >
                      First Name
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      value="lastname"
                      onClick={changeName}
                    >
                      Last Name
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div className="relative inline-block w-28">
              <label className="bg-white">Language</label>
              <button
                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={toggleDropdown3}
              >
                {language}
              </button>
              {isOpen3 && (
                <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      onClick={changeLanguage}
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      onClick={changeLanguage}
                    >
                      French
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                      onClick={changeLanguage}
                    >
                      Chinese
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div className="flex flex-col">
              <label className="bg-white">Quantity</label>
              <input
                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                value={inputNumber}
                onChange={handleInputNumber}
              />
            </div>

            <div>
              <button
                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={submitData}
              >
                Generate
              </button>
            </div>
          </div>
          <div className={`pt-24 px-16 pb-14 ${responseHide} `}>
            <p className="space-x-3 grid grid-cols-4 gap-4 text-center text-sm">
              <span className="hidden"></span>
              {fetchedData.map((item, index) => (
                <span className="bg-slate-100 rounded-md py-2 px-3" key={index}>{item}</span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FakeNameGenerator;
