import React, { useState } from "react";
import "../index.css";

function ImageGenerator() {
    const [imageData, setImageData] = useState('');
    const [gender, setGender] = useState('Random');
    const [isOpen, setIsOpen] = useState(false);
    const [filetype, setFiletype] = useState('png');
    const [isOpen2, setIsOpen2] = useState(false);
    const [responseHide, setResponseHide] = useState('hidden');
    const [loading, setLoading] = useState('hidden');

    const changeGender = (event) => {
        event.preventDefault();
        const innerValue = event.target.textContent;
        setGender(innerValue);
        setIsOpen(false)
    }

    const changeFileType = (event) => {
        event.preventDefault();
        const innerValue = event.target.textContent;
        setFiletype(innerValue);
        setIsOpen2(false)
    }

    const toggleDropdown = () => {
        setIsOpen2(false);
        setIsOpen(!isOpen);
    };

    const toggleDropdown2 = () => {
        setIsOpen(false);
        setIsOpen2(!isOpen2);
    };


    const submitData = () => {
        const data = {
            gender: gender.replace(/\s/g, '').toLowerCase(),
        };

        setLoading('block');
        setResponseHide('hidden');
        fetch('http://localhost:2000/generateimage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setImageData(data.image);
                setLoading('hidden');
                setResponseHide('flex')
            })
            .catch(error => {
                console.error(error);
            });
    };

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = `data:image/jpeg;base64, ${imageData}`;
        link.download = `image.${filetype}`;
        link.click();
    };

    return (
        <>
            <div className="h-screen w-full bg-slate-100 pt-5">
                <div className="sm:w-[80%] w-[90%] bg-white m-auto rounded-md py-6">
                    <h2 className='text-3xl font-semibold mt-3 mb-2 text-center'>Image Generator</h2>
                    <div className="flex items-end px-5 py-5 justify-center">
                        <div className="relative inline-block w-28 mr-5">
                            <label className="bg-white">Gender</label>
                            <button
                                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                onClick={toggleDropdown}
                            >
                                {gender}
                            </button>
                            {isOpen && (
                                <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                                    <li>
                                        <a
                                            className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                            onClick={changeGender}
                                        >
                                            Random
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                            value="male"
                                            onClick={changeGender}
                                        >
                                            Male
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                            value="female"
                                            onClick={changeGender}
                                        >
                                            Female
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>

                        <div>
                            <button
                                className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                onClick={submitData}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                    <div>
                        <img src="./loading.gif" className={`w-[200px] m-auto mt-8 ${loading} `} alt="" />
                    </div>
                    <div className={`pt-2 px-16 pb-14 flex flex-col items-center justify-center  ${responseHide} `}>
                        <img className="rounded-md w-[240px]" src={`data:image/jpeg;base64, ${imageData}`} />
                        <div className="mt-5 flex">
                            <div className="relative inline-block w-28 mr-5">
                                <button
                                    className="py-2 px-4 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    onClick={toggleDropdown2}
                                >
                                    {filetype}
                                </button>
                                {isOpen2 && (
                                    <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                onClick={changeFileType}
                                            >
                                                png
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="male"
                                                onClick={changeFileType}
                                            >
                                                jpg
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="female"
                                                onClick={changeFileType}
                                            >
                                                jpeg
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="female"
                                                onClick={changeFileType}
                                            >
                                                webp
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <button
                                className=" px-4 bg-gray-800 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                onClick={downloadImage}
                            >
                                Download
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ImageGenerator;
