import React, { useState } from "react";
import "../index.css";

function YoutubeThumbnailDownloader() {
    const [imageData, setImageData] = useState('');
    const [filetype, setFiletype] = useState('png');
    const [isOpen2, setIsOpen2] = useState(false);
    const [responseHide, setResponseHide] = useState('hidden');
    const [loading, setLoading] = useState('hidden');
    const [inputValue, setInputValue] = useState('');

    const changeFileType = (event) => {
        event.preventDefault();
        const innerValue = event.target.textContent;
        setFiletype(innerValue);
        setIsOpen2(false)
    }

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleUpload = () => {
        if (inputValue !== '') {


            const data = {
                url: inputValue,
            };

            setLoading('block');
            setResponseHide('hidden');
            fetch('http://localhost:2000/generatevideothumbnail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    setImageData(data.image);
                    setLoading('hidden');
                    setResponseHide('flex')
                })
                .catch(error => {
                    alert('Please Type correct URL');
                });
        } else {
            alert('Plase Type URL')
        }
    };

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = `data:image/jpeg;base64, ${imageData}`;
        link.download = `image.${filetype}`;
        link.click();
    };

    return (
        <>
            <div className="h-screen w-full bg-slate-100 pt-10">
                <div className='lg:w-[50%] w-[90%] bg-white m-auto rounded-md md:p-10 p-5'>
                    <h2 className='sm:text-3xl text-2xl font-semibold mb-7 text-center'>Youtube Thumbnail Downloader</h2>
                    <div className='sm:flex items-center'>
                        <div className='w-[100%]'>
                            <input autoComplete="off" onChange={handleInputChange} className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary" placeholder="Pase video URL here" type="text" id="formFile" />
                        </div>
                        <div>
                            <button onClick={handleUpload} className='bg-slate-600 w-full sm:mt-0 mt-5 hover:bg-slate-700 active:bg-slate-900 text-white py-[6px] px-4 sm:ml-3 ml-0 rounded-lg'>Upload</button>
                        </div>
                    </div>
                    <div>
                        <img src="./loading.gif" className={`w-[200px] m-auto mt-8 ${loading} `} alt="" />
                    </div>
                    <div className={`pt-10 px-16 pb-14 flex flex-col items-center justify-center  ${responseHide} `}>
                        <img className="rounded-md w-[240px]" src={`data:image/jpeg;base64, ${imageData}`} />
                        <div className="mt-5 flex">
                            <div className="relative inline-block w-28 mr-5">
                                <button
                                    className="py-2 px-4 mt-2 bg-gray-800 w-28 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    onClick={toggleDropdown2}
                                >
                                    {filetype}
                                </button>
                                {isOpen2 && (
                                    <ul className="absolute text-gray-700 pt-1 bg-white border border-gray-300 rounded-lg w-28 mt-2 shadow-lg">
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                onClick={changeFileType}
                                            >
                                                png
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="male"
                                                onClick={changeFileType}
                                            >
                                                jpg
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="female"
                                                onClick={changeFileType}
                                            >
                                                jpeg
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:bg-gray-100 px-4 py-2 block whitespace-no-wrap"
                                                value="female"
                                                onClick={changeFileType}
                                            >
                                                webp
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <button
                                className="py-2 px-4 mt-2 bg-gray-800 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                onClick={downloadImage}
                            >
                                Download
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default YoutubeThumbnailDownloader;
